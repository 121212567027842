import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeTypography } from 'designSystem';
import React, { Fragment, ReactNode } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { AvailableSizes } from 'types/enums';

interface IDropdownProps<ValueType = unknown, OptionType = ValueType> {
  currentValue?: ValueType | null;
  options: OptionType[];
  onChange: (option: ValueType) => void;
  renderItems?: (option: OptionType) => ReactNode;
  className?: string;
  fullWidth?: boolean;
  keyField?: keyof OptionType;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  'data-testid'?: string;
  errorMsg?: string;
  defaultOpen?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  size?: AvailableSizes;
}

const StyledFormControl = styled(FormControl)<{ 'hide-label': booleanish }>(
  ({ theme, 'hide-label': hideLabel }) => ({
    '& .MuiInputBase-root:hover': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiInputBase-root': {
      background: 'transparent',
    },
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
    },
    '& .MuiInput-underline:before': {
      content: 'none',
    },
    '& .MuiInput-underline:after': {
      content: 'none',
    },

    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      display: hideLabel === 'true' ? 'none' : 'unset',
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& .Mui-disabled': {
      background: theme.custom.colors.backgroundMedium,
    },
  })
);

const ErrorHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: 0,
  bottom: 0,
}));

const SizeRecord: Record<AvailableSizes, number> = {
  [AvailableSizes.SMALL]: 12,
  [AvailableSizes.MEDIUM]: 19,
  [AvailableSizes.LARGE]: 24,
};

const StyledSelect = styled(Select)<{ styledSize: keyof typeof AvailableSizes }>(
  ({ styledSize }) => ({
    '& .MuiSelect-select': {
      height: SizeRecord[styledSize],
      minHeight: SizeRecord[styledSize],
    },
  })
);

const DropDown = <ValueType = unknown, OptionType = ValueType>({
  currentValue,
  options,
  onChange,
  renderItems = (item: OptionType) => <>{item}</>,
  className,
  fullWidth,
  keyField,
  placeholder = '',
  label = '',
  disabled = false,
  'data-testid': dataTestId = '',
  errorMsg,
  defaultOpen,
  variant = 'standard',
  size = AvailableSizes.MEDIUM,
  ...props
}: IDropdownProps<ValueType, OptionType>) => {
  return (
    <StyledFormControl fullWidth={fullWidth} {...props} hide-label={Booleanish(!!currentValue)}>
      {label && !currentValue && (
        <InputLabel variant="outlined" shrink={false} id="select-label">
          {label}
        </InputLabel>
      )}
      <StyledSelect
        id={dataTestId}
        styledSize={size}
        variant={variant}
        className={className}
        // in order for the workaround with the placeholder to work, the value may only be set if currentValue actually exists
        value={!!currentValue && currentValue}
        onChange={(event: SelectChangeEvent<unknown>) => onChange(event.target.value as ValueType)}
        labelId="select-label"
        MenuProps={{ disablePortal: true }}
        error={!!errorMsg}
        // workaround to display placeholder
        renderValue={
          !currentValue
            ? () => (
                <ThemeTypography variant="BODY_SMALL" color="GRAY_40">
                  {placeholder}
                </ThemeTypography>
              )
            : undefined
        }
        disabled={disabled}
        {...props}
        inputProps={{
          'data-testid': dataTestId,
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              value={(keyField ? option[keyField] : option) as unknown as string}
              key={index}
              data-cy="dropdown-item"
            >
              {renderItems(option)}
            </MenuItem>
          );
        })}
      </StyledSelect>
      {errorMsg && <ErrorHelperText>{errorMsg}</ErrorHelperText>}
    </StyledFormControl>
  );
};

export default DropDown;
