import { useQuery } from '@apollo/client';
import { Box, styled } from '@mui/material';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { Loader } from 'components/Forms';
import { ActionContainer, ErrorState } from 'components/Structure';
import { useDialog, useMessages } from 'components/hooks';
import { ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { GET_DATASETS } from 'graphql/queries/dataset.queries';
import { GET_BASE_PARTNER_COMPANY } from 'graphql/queries/partner.queries';
import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EDatasetExportFormat,
  EDatasetExportType,
  IDataset,
  IDatasetsResponse,
} from 'types/dataset.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { removeGraphConnections } from 'utils/graphql.utils';
import DataImportOverviewCard from '../DataImportOverviewCard';
import DatasetDetailsCard from '../DatasetDetailsCard';
import DatasetOverviewCard from '../DatasetOverviewCard';
import { DATA_IMPORT_CATEGORY_CONFIG } from '../constants/dataImportConfig';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
}));

const DatasetContainer = styled(Box)(() => ({
  flexWrap: 'wrap',
  display: 'flex',
}));

const GeoDataImport: FC = () => {
  const { setErrorMessage } = useMessages();
  const { id: partnerId } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { deleteDataset: deleteEUDRDataset, exportDataset } = useDatasetMutation();
  const { openDialog } = useDialog();
  const { data, loading, error } = useQuery<IDatasetsResponse>(GET_DATASETS, {
    variables: {
      ...(partnerId ? { filters: { ownedById: partnerId } } : {}),
    },
  });

  const { data: partnerData } = useQuery<{ partner: IBasePartnerCompany }>(
    GET_BASE_PARTNER_COMPANY,
    {
      variables: {
        id: partnerId,
      },
      skip: !partnerId,
    }
  );
  const [datasetDetails, setDatasetDetails] = useState<IDataset>();

  const datasets = data ? removeGraphConnections(data.datasets) : [];
  const categoryConfig = DATA_IMPORT_CATEGORY_CONFIG[0];

  const handleClickUpload = () => {
    openDialog({
      type: 'CREATE_DATASET_DECISION',
      props: { datasetOwnedBy: partnerData?.partner },
    });
  };

  const handleClickViewDataset = (datasetId: string) => navigate(`datasets/${datasetId}`);

  const handleClickDeleteDataset = (dataset: IDataset) => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete dataset',
        itemTitle: datasets.find(item => item.id === dataset.id)?.title,
        text: 'Are you sure you want to delete this dataset? All information will be lost and you will not be able to get this information back.',
        onSubmit: async () => {
          await deleteEUDRDataset(dataset);
          setDatasetDetails(undefined);
        },
        submitText: 'Delete',
      },
    });
  };

  const handleClickExportDataset = async (datasetId: string) => {
    // TODO: Add loading indicator on button
    try {
      const response = await exportDataset({
        datasetId,
        exportType: EDatasetExportType.VALIDATED,
        exportFormat: EDatasetExportFormat.EXCEL,
      });

      const fileUrl = response?.data?.exportDataset?.fileUrl;
      if (fileUrl) {
        // Wait for the file to actually be ready to be downloaded
        await setTimeout(() => window.open(fileUrl, '_blank'), 500);
      } else {
        throw new Error('Could not export the dataset');
      }
    } catch (error) {
      setErrorMessage('Could not export the dataset. Please try again or contact the support');
    }
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <Container>
      {datasets.length > 0 ? (
        <>
          <ActionContainer>
            <ThemeButton
              size="medium"
              color="YELLOW"
              startIcon={<Icon name="plus" />}
              onClick={handleClickUpload}
            >
              Add new dataset
            </ThemeButton>
          </ActionContainer>
          <Box mt={4}>
            <Box gap={2} display="flex" justifyContent="space-between">
              <DatasetContainer gap={2}>
                {datasets.map(dataset => (
                  <DatasetOverviewCard
                    key={dataset.id}
                    dataset={dataset}
                    selected={datasetDetails?.id === dataset.id}
                    onClick={() => setDatasetDetails(dataset)}
                  />
                ))}
              </DatasetContainer>
              {datasetDetails !== undefined && (
                <DatasetDetailsCard
                  dataset={datasetDetails}
                  buttonText="View farms"
                  onClickDataset={handleClickViewDataset}
                  onDownloadDataset={handleClickExportDataset}
                  onDeleteDataset={handleClickDeleteDataset}
                />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <DataImportOverviewCard
          title={`Add your ${categoryConfig.dataLabel} geo-data to enable tracing of impact along your supply chains`}
          onAddTableClick={handleClickUpload}
        />
      )}
    </Container>
  );
};

export default GeoDataImport;
