import { Box, styled } from '@mui/material';
import DatasetForm from 'components/ComponentsLibrary/Forms/DatasetForm';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import WarningBanner from 'components/DataImport/WarningBanner';
import { useDocumentUpload } from 'components/DocumentLibrary/hooks';
import { useGeoDataCollection } from 'components/DueDiligenceProcess/Context/SubSections/GeoDataCollectionContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { useDialog, useMessages } from 'components/hooks';
import { ErrorState, FlexBox } from 'components/Structure';
import { originTableSettingsSchema } from 'constants/schemas/compliance.schema';
import {
  datasetSchema,
  IDatasetFormValue,
  IGeoUploadFormValue,
} from 'constants/schemas/geoUpload.schema';
import { ThemeButton, ThemeTypography } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Formik, FormikProps } from 'formik';
import useDueDiligenceProcessMutations from 'hooks/useDueDiligenceProcessMutations';
import isEqual from 'lodash/isEqual';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { booleanish } from 'types/booleanish.types';
import { IOriginTableSettings } from 'types/dataImport.types';
import { EDatasetExportFormat, EDatasetExportType, EDatasetStatus } from 'types/dataset.types';
import { DocumentCategories } from 'types/document.types';
import {
  matchColumnMappingToColumnKey,
  transformColumnNamesToTableSettingColumns,
} from 'utils/dataset.utils';
import DatasetValidationForm, { IDatasetTableHandle } from '../Forms/DatasetValidationForm';

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const InfoContainer = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '6px',
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
  background: theme.custom.themeColors.primary[5],
}));

const GeoDataCollectionUploadValidation: FC = () => {
  const { setErrorMessage } = useMessages();
  const { startDatasetPreProcessing, updateDataset, exportDataset } = useDatasetMutation();
  const {
    dueDiligenceProcess,
    processedDatasets,
    setSelectedDatasetIds,
    onNextClick,
    onPreviousClick,
    setProcessedDatasets,
  } = useGeoDataCollection();
  const { createDueDiligenceProcess, updateDueDiligenceProcess } =
    useDueDiligenceProcessMutations();
  const [uploadFile] = useDocumentUpload();

  const formRef = useRef<FormikProps<IDatasetFormValue>>(null);
  const { openDialog } = useDialog();
  const datasetTableRef = useRef<IDatasetTableHandle>(null);

  const [tableSettings, setTableSettings] = useState<IOriginTableSettings>(
    originTableSettingsSchema.default()
  );
  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isPreparingExport, setIsPreparingExport] = useState(false);

  const selectedDataset = useMemo(
    () => processedDatasets?.datasets.find(({ id }) => id === selectedDatasetId),

    [processedDatasets, selectedDatasetId]
  );

  const initialValues: IDatasetFormValue = {
    ...datasetSchema.default(),
    ...(selectedDataset
      ? {
          datasetId: selectedDataset.id,
          title: selectedDataset.title,
          rawMaterialId: selectedDataset.rawMaterial.id,
          countryCode: selectedDataset.originCountry,
          ownedBy: selectedDataset.ownedBy,
        }
      : {}),
  };

  useEffect(() => {
    // If something is already selected, do not change it
    if (!processedDatasets || selectedDatasetId) {
      return;
    }
    // find the first dataset that is has the status 'VALIDATION_FAILED'
    const failedDataset = processedDatasets.datasets.find(
      dataset => dataset.status === EDatasetStatus.VALIDATION_FAILED
    );
    if (failedDataset) {
      setSelectedDatasetId(failedDataset.id);
    } else if (processedDatasets.datasets.length) {
      // Otherwise select the first dataset
      setSelectedDatasetId(processedDatasets.datasets[0].id);
    }
  }, [processedDatasets, selectedDatasetId]);

  const onTableSettingsChange = useCallback(
    (settings: IOriginTableSettings) => {
      setTableSettings(settings);
    },
    [setTableSettings]
  );

  const selectedDatasetTableRepresentation = useMemo(() => {
    if (selectedDataset && selectedDataset.tableRepresentation?.length) {
      // TODO: The type of the tableRepresentation is still an array from the backend but it should be just one table representation
      return selectedDataset.tableRepresentation[0];
    }
  }, [selectedDataset]);

  /**
   * Whenever a dataset changes we need to update the table settings according to its table
   */
  useEffect(() => {
    if (selectedDatasetTableRepresentation) {
      setTableSettings(settings => ({
        ...settings,
        columns: transformColumnNamesToTableSettingColumns(
          selectedDatasetTableRepresentation.columnsNames
        ),
      }));
    }
  }, [selectedDatasetTableRepresentation]);

  const handleSuccessfulProcessing = useCallback(
    async (datasetIds: string[]) => {
      if (!datasetIds.length) {
        return;
      }

      let dueDiligenceProcessId = dueDiligenceProcess?.id;
      if (!dueDiligenceProcessId) {
        // Create or update the due diligence process
        const createdDueDiligenceProcess = await createDueDiligenceProcess({
          complianceId: '00000000-0000-0000-0000-000000000000',
          datasetIds,
        });
        dueDiligenceProcessId =
          createdDueDiligenceProcess.data?.createGeoDataProcess.geoDataProcess.id;
        if (!dueDiligenceProcessId) {
          throw new Error('Could not create the due diligence process');
        }
      } else {
        // Update the due diligence process
        await updateDueDiligenceProcess(dueDiligenceProcessId, {
          datasetIds,
        });
      }

      // As soon as we are sure all datasets are actually created we select them
      setSelectedDatasetIds(datasetIds);
      onNextClick(dueDiligenceProcessId);
    },
    [
      dueDiligenceProcess,
      createDueDiligenceProcess,
      setSelectedDatasetIds,
      onNextClick,
      updateDueDiligenceProcess,
    ]
  );

  // TODO: Move the row error count calculation to the backend
  const issueCount =
    selectedDatasetTableRepresentation?.rows?.reduce(
      (prev, row) => prev + (Object.values(row).some(value => value?.error) ? 1 : 0),
      0
    ) || 0;

  // Each dataset should have a table representation
  if (
    !processedDatasets?.datasets.length ||
    !processedDatasets.datasets.every(({ tableRepresentation }) => !!tableRepresentation?.length)
  ) {
    return <ErrorState />;
  }

  const handleOpenSettings = () => {
    openDialog({
      type: 'DATASET_TABLE_SETTINGS',
      props: { settings: tableSettings, hideImportSettings: true, onTableSettingsChange },
    });
  };

  const updateDatasetIfNeeded = async (
    values: Partial<
      Pick<IGeoUploadFormValue, 'title' | 'rawMaterialId' | 'countryCode' | 'ownedBy'>
    > & {
      datasetId: string; // datasetid is required
      files?: { id: string }[];
    }
  ) => {
    if (values.datasetId && !isEqual(initialValues, values)) {
      return await updateDataset({
        ...values,
      });
    }
  };

  const validateAndSubmitDatasetChanges = async (
    datasetId: string,
    formValues?: IDatasetFormValue
  ) => {
    if (
      !datasetTableRef.current ||
      !datasetTableRef.current.getDataAsCsv ||
      !datasetTableRef.current.runValidations
    ) {
      throw new Error('Could not access the table data');
    }

    const values = formValues || formRef.current?.values;

    // Run validations
    const isValid = datasetTableRef.current.runValidations();

    if (!isValid) {
      return false;
    }

    // Extract data as csv from table representation
    const csvData = datasetTableRef.current.getDataAsCsv({
      removeFirstAndLastColumn: true,
      // By default the column id is used as header and adds a space based on the camel case
      processHeaderCallback: ({ column }) => column.getColId(),
    });

    // Create a file from the csv data
    const file = new File([csvData], `${values?.title}-validated.csv`, {
      type: 'text/csv',
    });

    // Upload the file to the document library
    // @ts-ignore
    const documentCreationResponse = await uploadFile({
      file,
      category: DocumentCategories.GEOGRAPHICAL_FEATURES,
    });

    if (!documentCreationResponse?.record.id) {
      throw new Error('Could not create the document');
    }

    await updateDatasetIfNeeded({
      ...values,
      datasetId,
      files: [{ id: documentCreationResponse.record.id }],
    });

    return true;
  };

  const handleSubmitValidation = async (values: IDatasetFormValue) => {
    try {
      setLoading(true);

      // Save the changes of the currently selected dataset
      if (selectedDatasetId) {
        const isValid = await validateAndSubmitDatasetChanges(selectedDatasetId, values);
        if (!isValid) {
          setLoading(false);
          setErrorMessage('Please correct the validation errors in the table');
          return;
        }
      }

      if (!datasetTableRef.current) {
        throw new Error('Could not access the table data');
      }

      const selectedDatasetMapping = datasetTableRef.current.getColumnsMapping();
      const datasetsToProcess = processedDatasets.datasets.map(({ id, tableRepresentation }) => {
        if (id === selectedDatasetId) {
          return {
            datasetId: id,
            mappedColumns: selectedDatasetMapping,
          };
        }
        return {
          datasetId: id,
          mappedColumns: tableRepresentation?.[0].columnsNames.map(columnName => ({
            sourceColumnName: matchColumnMappingToColumnKey(columnName),
            targetColumnName: columnName,
          })),
        };
      });

      const preProcessingResponse = await startDatasetPreProcessing(datasetsToProcess);

      if (!preProcessingResponse.data?.preProcessDatasets) {
        throw new Error('Failed to start the pre processing');
      }

      // Update processing response in the state to show the correct status
      setProcessedDatasets(preProcessingResponse.data?.preProcessDatasets);

      formRef.current?.setSubmitting(false);
      setLoading(false);

      if (
        preProcessingResponse.data?.preProcessDatasets.datasetProcessingErrors?.length ||
        preProcessingResponse.data?.preProcessDatasets.datasets.some(
          dataset => dataset.status !== EDatasetStatus.VALIDATED
        )
      ) {
        setErrorMessage('Please correct all validation errors in the table');
        return;
      }

      openDialog({
        type: 'CONFIRM_DATASET_IMPORTS',
        props: {
          datasets: datasetsToProcess,
          onProcessingStart: () => {
            formRef.current?.setSubmitting(true);
            setLoading(true);
          },
          onProcessingComplete: handleSuccessfulProcessing,
        },
      });
    } catch (error) {
      console.error('Error while saving the dataset', error);
      setErrorMessage('Could not save the dataset. Please try again or contact the support');
      setLoading(false);
      formRef.current?.setSubmitting(false);
    }
  };

  /**
   * To be able to switch between datasets we need to save the changes of the current dataset
   * and run the pre processing for the selected dataset
   */
  const handleDatasetClick = async (datasetId: string) => {
    if (loading || !selectedDatasetId || datasetId === selectedDatasetId) {
      setSelectedDatasetId(datasetId);
      return;
    }

    try {
      const currentlySelectedDatasetId = selectedDatasetId;
      setLoading(true);

      const isValid = await validateAndSubmitDatasetChanges(currentlySelectedDatasetId);
      if (!isValid) {
        setErrorMessage(
          'Please correct the validation errors in the table before switching to another dataset'
        );
        return;
      }

      if (!datasetTableRef.current) {
        throw new Error('Could not access the table data');
      }

      const mappedColumns = datasetTableRef.current.getColumnsMapping();

      // Running the pre processing for the selected dataset
      const preProcessingResponse = await startDatasetPreProcessing([
        { datasetId: currentlySelectedDatasetId, mappedColumns },
      ]);

      const updatedProcessedDatasets = preProcessingResponse.data?.preProcessDatasets;
      if (!updatedProcessedDatasets) {
        throw new Error('Failed to start the pre processing');
      }

      // Find and update processing response in the state to show the correct status
      setProcessedDatasets(prev => {
        if (!prev) {
          return preProcessingResponse.data?.preProcessDatasets;
        }

        return {
          prev,
          datasets: [
            ...prev.datasets.filter(({ id }) => id !== currentlySelectedDatasetId), // Remove the old dataset values
            ...updatedProcessedDatasets.datasets, // Add the updated dataset values
          ],
          datasetProcessingErrors: [
            ...(prev.datasetProcessingErrors
              ? prev.datasetProcessingErrors.filter(
                  ({ datasetId: id }) => id !== currentlySelectedDatasetId
                ) // Remove the old dataset errors values
              : []),
            ...(updatedProcessedDatasets.datasetProcessingErrors // Add the updated dataset values
              ? updatedProcessedDatasets.datasetProcessingErrors
              : []),
          ],
        };
      });

      if (
        preProcessingResponse.data?.preProcessDatasets.datasetProcessingErrors?.length ||
        preProcessingResponse.data?.preProcessDatasets.datasets.some(
          dataset => dataset.status !== EDatasetStatus.VALIDATED
        )
      ) {
        formRef.current?.setSubmitting(false);
        setErrorMessage('Please correct all validation errors in the table');
        return;
      }
    } catch (error) {
      console.error('Error while saving the dataset', error);
      setErrorMessage('Could not save the dataset. Please try again or contact the support');
    } finally {
      setLoading(false);
      formRef.current?.setSubmitting(false);
      setTableSettings(originTableSettingsSchema.default());
      setSelectedDatasetId(datasetId);
    }
  };

  const handleClickDownload = async () => {
    if (!selectedDatasetId) {
      return;
    }
    try {
      setIsPreparingExport(true);
      const response = await exportDataset({
        datasetId: selectedDatasetId,
        exportType: EDatasetExportType.VALIDATED,
        exportFormat: EDatasetExportFormat.EXCEL,
      });

      const fileUrl = response?.data?.exportDataset?.fileUrl;
      if (fileUrl) {
        // Wait for the file to actually be ready to be downloaded
        await setTimeout(() => window.open(fileUrl, '_blank'), 500);
      } else {
        throw new Error('Could not export the dataset');
      }
    } catch (error) {
      setErrorMessage('Could not export the dataset. Please try again or contact the support');
    } finally {
      setIsPreparingExport(false);
    }
  };

  return (
    <Formik<IDatasetFormValue>
      innerRef={formRef}
      enableReinitialize
      validationSchema={datasetSchema}
      initialValues={initialValues}
      onSubmit={handleSubmitValidation}
    >
      {formProps => (
        <>
          <DueDiligenceProcessSubSectionNavigation
            allowNextStepNavigation
            customNextStepButton={{ text: 'Save & verify', color: 'YELLOW' }}
            nextStepLoading={loading}
            onNextStepClick={formProps.submitForm}
            onPreviousStepClick={onPreviousClick}
          >
            <InfoContainer>
              <ThemeTypography variant="BODY_MEDIUM">
                For single location plots that are missing a size attribute, you can add the size
                here. Otherwise, when this geo-data is used for an EUDR deforestation analysis, the
                plot size of each plot with a single coordinate will during the analysis default to
                4 ha, according to regulation requirements.
              </ThemeTypography>
            </InfoContainer>

            <Box display="flex" mt={2} gap={2} flexWrap="wrap">
              {/* Sort by title so the order does not change whenever updating a dataset */}
              {processedDatasets.datasets
                .sort((a, b) => (a.title <= b.title ? -1 : 1))
                .map(({ status, id, title, rawMaterial, originCountry, ownedBy: partner }) => (
                  <DatasetContainer key={id} onClick={() => handleDatasetClick(id)}>
                    <DatasetItem
                      title={title}
                      commodity={rawMaterial.title}
                      location={originCountry}
                      owner={partner.name}
                      active={id === selectedDatasetId}
                      status={
                        status === EDatasetStatus.VALIDATED || status === EDatasetStatus.COMPLETED
                          ? 'valid'
                          : 'error'
                      }
                    />
                  </DatasetContainer>
                ))}
            </Box>
          </DueDiligenceProcessSubSectionNavigation>

          <Box display="flex" flexDirection="column" mt={2} gap={2}>
            <DatasetForm
              {...formProps}
              isTableView
              showOnlyEudrRelevantRawMaterials
              hideSubmitButton
              onTableSettingsClick={handleOpenSettings}
            />

            {!!issueCount && (
              <>
                <WarningBanner
                  severity="error"
                  message={`Dataset could not be saved. ${issueCount} rows contain errors. Please correct all errors per row in the table below. Click on Save & verify to check whether all errors are resolved.`}
                  issueCount={issueCount}
                />

                <ThemeButton
                  color="WHITE"
                  loading={isPreparingExport}
                  startIcon={<Icon name="download" />}
                  onClick={handleClickDownload}
                >
                  Download file with highlighted issues
                </ThemeButton>
              </>
            )}

            <DatasetValidationForm
              datasetTableRef={datasetTableRef}
              isEUDR
              mode="add-edit"
              tableSettings={tableSettings}
              tableRepresentation={selectedDatasetTableRepresentation}
            />
          </Box>
        </>
      )}
    </Formik>
  );
};

export default GeoDataCollectionUploadValidation;
